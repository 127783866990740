#map-view {
  z-index: 10000;
  height: 100%;
}

#map-view__header {
  padding: 1.6rem;
}

#map-view__header__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.4rem;
}

#map-view__header__form :first-child {
  margin-bottom: 1.6rem;
  height: 4.4rem;
}

#map-view__buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 1.6rem;
}

#map-view__buttons > * {
  margin: 0.5rem;
}

.list {
  display: flex;
}

.list > *:not(:last-child)::after {
  content: ',';
}

.details__header__controls {
  flex-wrap: wrap;
}

.details__header__controls > * {
  margin: 0.5rem;
}

.signed {
  background-color: var(--success-color-light);
  padding: 0.5rem;
  color: white;
  border-radius: 4px;
}

.not-signed {
  background-color: var(--error-color);
  color: white;
  padding: 0.5rem;
  border-radius: 4px;
}

.status {
  padding: 0.5rem;
  border-radius: 4px;
  color: white;
}

.no-marker::marker {
  content: none;
}

#images__ul img:hover {
  cursor: pointer;
}

#images__ul {
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
  flex-wrap: wrap;
  gap: 1rem;
}

.images__li {
  list-style-type: none;
}

.h1-job-details {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.h2-job-details {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.job-detail {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
}

.job-detail:last-child {
  margin-bottom: 0;
}

.job-details-divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 1.5em 2rem;
  display: flex;
}
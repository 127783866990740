.report-rows {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid var(--background-color);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  background-color: white;
  overflow-x: auto;
}

.report-rows-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid var(--background-color);
  font-weight: bold;
  font-size: 1.2rem;
  background-color: rgba(234, 234, 234, 1);
}

.report-rows-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--background-color);
}

.report-rows-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
}

.report-rows-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.report-rows-header span,
.report-rows-row span {
  flex: 1;
  text-align: left;
  word-break: break-word;
  overflow-wrap: break-word;
} 

.report-rows-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-rows-controls button {
  padding: 0 0.3rem ;
  margin: 0;
}

.popup--big,
.popup--medium,
.popup--small {
  background-color: #fff;
  box-shadow: 1px 1px 2px 0px var(--dark-color-transparent-50);
  opacity: 1;
  animation-name: fade-in;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  display: flex;
  flex-direction: column;
  z-index: 100;
  margin-bottom: 100px;
  border-radius: 10px;
}

.popup--big {
  position: absolute;
  top: 0;
  left: calc(50% - 30rem);
  width: 60rem;
  min-height: 200px;
  margin: 2.4rem 0;
}

.popup--medium,
.popup--small {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -12.5%);
}

.popup--medium {
  width: 50rem;
}

.popup--small {
  width: 40rem;
}

.popup--min {
}

.popup__content {
  padding: 1.6rem;
}

.close-button-container {
  display: flex;
  justify-content: flex-end;
  box-shadow: 0px 1px 23px -12px rgba(0, 0, 0, 0.75);
  padding: 1.6rem;
}

.close-button:hover {
  cursor: pointer;
}

.details-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.details-section div:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 800px) {
  .popup--big,
  .popup--medium {
    box-shadow: none;
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    transform: none;
    margin: 0 !important;
    min-width: auto;
    width: 98%;
    height: 99%;
  }
}

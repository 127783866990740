tr {
  display: flex;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}

tr td {
  padding: 0.75em 0;
  background-color: transparent;
  color: black;

  text-align: left;

  min-width: 120px;
}

table tr td {
  color: #000;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
  display: flex;
  align-items: center;
  border: none;
}

table > tbody > tr > td {
  min-width: 200px;
}

table tr td:first-child {
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

table tr td:nth-child(6) {
  /*littra*/
  padding-left: 25px;
}

table tr td:nth-child(2) {
  /*Order nr*/
  min-width: 100px;
}

table tr td:nth-child(7) {
  /*Timmar*/
  min-width: 100px;
}

.status-box {
  color: #fff;

  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 2px 8px;
  border-radius: 2px;
}

.icon {
  width: 15px;
  height: 15px;
}

#date-picker {
  border: 1px solid #0000001A;
  border-radius: 5px;
  display: flex;
  background-color: white;
}

#date-picker__buttons {
  display: flex;
  border-left: 1px solid #0000001A;
  border-right: 1px solid #0000001A;
  padding: 1rem;
  /* min-width: 20rem; */
}

@media screen and (max-width: 800px) {
  #date-picker__picker {
    position: fixed;
    top: 6.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

#date-picker__picker__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 0;
}

#date-picker__picker__controls div {
  display: flex;
  align-items: center;
}

#date-picker__picker__controls p {
  font-style: italic;
}

.fa-angle-up,
.fa-angle-down {
  font-size: 2.4rem;
  font-weight: bold;
}

#sign__scetch-pad {
  height: 20rem;
  width: calc(100vw - 4.8rem);
  margin: 2.4rem auto;
  background-color: var(--background-color);
}

#sign {
  display: flex;
  flex-direction: column;
}

#sign h2 {
  margin-bottom: 2.4rem;
}

#name,
#email,
#comment {
  width: 100%;
  margin-bottom: 2.4rem;
}

#comment {
  margin-bottom: 1.2rem;
}

#sign__info {
  margin-bottom: 2.4rem;
}

#sign__sign {
  display: flex;
  flex-direction: column;
}

#sign__sign button {
  align-self: flex-end;
}

.date {
  font-weight: bold;
}

.today__desktop {
  background-color: hsl(328, 86%, 81%);
}

#no-jobs {
  padding: 1rem 24px;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Open Sans';
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.date-container {
  background-color: white;
  padding: 16px 24px;
  width: 100%;
}
.date-container td {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
